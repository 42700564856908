<template>
  <div class="verification container">
    <div class="row align-items-center justify-content-between col-gap-90">
      <div class="col-md-6">
        <base-alerts />
        <h1>
          Your <br />
          verification <br />
          code is :
        </h1>
        <verify-email @success="varified"></verify-email>
      </div>
      <div class="col-md-6">
        <div class="image">
          <img
            src="@/assets/images/Securitylogo.png"
            class="img-fluid"
            alt="Securitylogo-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import verifyEmail from "@/mixin/verifyEmail";
import { mapActions } from "vuex";
export default {
  mixins: [verifyEmail],
  data() {
    return {
      processing: false,
      profile: {},
      password: null,
      retype: null,
      image: null,
      buttonText: "Save",
      data: {
        firstName: "",
        lastName: "",
        birthdate: "",
        address: "",
      },
    };
  },
  components: {
    verifyEmail: () =>
      import(
        /* webpackChunkName: "verifyemail" */ "@/components/brand/VerifyEmail.vue"
      ),
  },
  created() {
    //this.resendPIN();
  },
  methods: {
    varified() {
      this.$router.push("/brand/");
    },
  },
};
</script>

<style lang="scss" scoped>
.verification {
  @media screen and (max-width: 1600px) {
    .image {
      img {
        max-width: 480px;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .image {
      img {
        max-width: 375px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .image {
      img {
        max-width: 325px;
      }
    }
  }
  .form-control {
    height: 76px;
  }
}
::v-deep.invalid-feedback {
  position: static !important;
}
.editProfile {
  .topHead {
    margin-bottom: 12px;
    align-items: center;
    h1 {
      margin: 0;
    }
    a {
      width: auto;
      font-size: rem(14px);
      height: auto;
      font-weight: 900;
      border-radius: 50px;
      min-height: auto;
      @include flex(center, center);
      flex-wrap: nowrap;
      padding: rem(10px) rem(54px);
      &:hover {
        &::before {
          border-radius: 50px;
        }
      }
      svg {
        width: 20px;
        margin-left: 13px;
      }
    }
  }
  .UploadImage {
    overflow: hidden;
    text-align: center;
    @include flex(center, center);
    ::v-deepimg {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 100%;
      margin: 10px;
      @media screen and (max-width: 767px) {
        width: 150px;
        height: 150px;
      }
    }
  }
  h1 {
    font-size: rem(20px);
    margin-bottom: rem(16px);
  }
  form {
    max-width: 100%;
  }
  .form-control {
    padding: rem(14px) rem(10px);
    border: 1px solid #caced5;
    border-radius: 4px;
    font-size: rem(16px);
    font-weight: 400;
    color: var(--textPrimary);
    &::placeholder {
      opacity: 0.8;
    }
    &:focus {
      border: 1px solid var(--primary) !important;
    }
  }
  .socialLinks {
    list-style: none;
    @include flex(center, space-between);
    padding: 0 10px;
    li {
      flex: 0 0 48%;
      max-width: 48%;
      margin-bottom: 12px;
      img {
        margin-right: 6px;
      }
      a {
        font-size: 14px;
        font-weight: 600;
        color: var(--textPrimary);
        border: 1px solid #caced5;
        border-radius: 50px;
        @include flex(center, center);
        padding: rem(18px) rem(10px);
        text-decoration: none;
        min-width: 213px;
        min-height: 65px;
        background-color: #fff;
        img {
          width: 21px;
          height: 21px;
        }
        @media screen and (min-width: 1025px) {
          &:hover {
            border-color: var(--primary);
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      li {
        a {
          min-width: auto;
        }
      }
    }
    @media screen and (max-width: 575px) {
      li {
        flex: 0 0 100%;
        max-width: 100%;
        a {
          min-height: 55px;
        }
      }
    }
  }
  .button-row {
    margin-top: 0;
    padding: 0 10px;
    .btn {
      width: 100%;
    }
  }
  .form-group {
    &.upload {
      background: #e8e8f0;
      border-radius: 8px;
      height: 52px;
      position: relative;
      margin: 0;
      width: 96%;
      margin: auto;
      margin-bottom: 11px;
      transition: 0.4s ease all;
      span {
        position: absolute;
        font-size: rem(20px);
        font-weight: 600;
        color: var(--textPrimary);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: rem(18px);
        }
      }
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
      }
      @media screen and (min-width: 1025px) {
        &:hover {
          background: var(--primary);
          span {
            color: #fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    > .row {
      flex-direction: column-reverse;
    }
    &__image {
      max-width: 300px;
      margin: auto auto rem(30px) auto;
    }
  }
}
::v-deep {
  .b-form-btn-label-control {
    border-radius: 4px;
    border: 1px solid #ced4da !important;
  }
  .b-form-datepicker.b-form-btn-label-control.form-control > .form-control {
    border-radius: 8px;
    color: var(--textPrimary) !important;
    font-size: rem(16px) !important;
    font-weight: 400 !important;
    // padding-left: rem(24px);
  }
  .b-form-datepicker .form-control.text-muted {
    opacity: 0.8;
  }
  .image-selected img {
    max-height: 175px;
  }
}
</style>
