import { RESEND_PIN } from "@/graphql/common/mutations";
import { MESSAGES } from "@/_helpers/notifications";
import { CONFIRM_EMAIL } from "@/graphql/brand/mutations";
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["updateUser"]),
    resendPIN() {
      this.$apollo
        .mutate({
          mutation: RESEND_PIN,
        })
        .then((data) => {
          if (data) {
            if (data.data.resendPin.state == "success") {
              this.notify("success", MESSAGES.PIN_SENT);
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    async verifyCode() {
      this.processing = true;
      await this.$apollo
        .mutate({
          mutation: CONFIRM_EMAIL,
          variables: {
            pin: this.pin,
          },
        })
        .then((data) => {
          if (data) {
            if (data.data.confirmEmail.state == "success") {
              this.notify("success", data.data.confirmEmail.msg);
              this.updateUser();
              this.$emit("success");
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
        });
    },
  },
};
